import ErrorToast from "./ErrorToast";
import InfoToast from "./InfoToast";
import SuccessToast from "./SuccessToast";
import Toast from "./Toast";
import WarningToast from "./WarningToast";
import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";

export const displayToast = (data) => {
  let component;
  const container = document.createElement("div");
  document.body.appendChild(container);
  let root = null;

  switch (data.type) {
    case "error":
      component = <ErrorToast toastKey={Math.random()} {...data} />;
      break;
    case "success":
      component = <SuccessToast toastKey={Math.random()} {...data} />;
      break;
    case "warn":
      component = <WarningToast toastKey={Math.random()} {...data} />;
      break;
    case "info":
      component = <InfoToast toastKey={Math.random()} {...data} />;
      break;
    default:
      component = <Toast toastKey={Math.random()} content={data.content} />;
  }
  root = createPortal(component, container);
  createRoot(container).render(root);
};

import React, { useEffect, useState } from "react";
import classes from "./Toast.module.css";
import Toast from "./Toast";
import images from "../../assets/svg";
const InfoToast = ({ title, message, autoHideDuration, toastKey }) => {
  const { infoIcon } = images;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, [toastKey]);

  return (
    <Toast
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      onClose={() => setOpen(false)}
      autoHideDuration={autoHideDuration}
      toastKey={title}
    >
      <div className={`${classes["toast"]} ${classes["info-toast"]}`}>
        <div className={classes["toast-title-container"]}>
          <img className={classes["toast-icon"]} src={infoIcon} alt="info" />
          <div className={classes["toast-title"]}>{title}</div>
        </div>
        <div className={classes["toast-message"]}>{message}</div>
      </div>
    </Toast>
  );
};

export default InfoToast;

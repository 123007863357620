import React from "react";
import { CalcWidthValue } from "../helper/CommonValue";
import useWindowDimensions from "../helper/dimensionsHelpers";
import StoreCard from "./StoreCard";
import PharmacyComponent from "./PharmacyComponent";
import BuisnessComponent from "./BuisnessComponent";
import MultiserviceComponent from "./MultiserviceComponent";
import EmpowerComponent from "./EmpowerComponent";
import TrueTabNewsComponent from "./TrueTabNewsComponent";

const HomeComponent = () => {
  const { width } = useWindowDimensions();
  return (
    <div style={{ paddingTop: "10px" }}>
      {/* Online Shop */}
      <section>
        <StoreCard />
      </section>
      <div>
        <h2
          style={{
            padding: "70px",
            height: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontSize: CalcWidthValue(45, width),
            fontWeight: "bold",
            color: "#5C2482",
          }}
        >
          Everything you need
          <br /> to grow your business
        </h2>
      </div>
      <PharmacyComponent />
      <BuisnessComponent />
      <MultiserviceComponent />
      <TrueTabNewsComponent />
      <EmpowerComponent />
    </div>
  );
};

export default HomeComponent;

import React from "react";
import TextField from "@mui/material/TextField";

const CustomTextField = React.forwardRef((props, ref) => {
  const {
    label = "",
    placeholder = label || "",
    value = "",
    inputTextColor = "#333333",
    hoverColor = "rgb(92, 36, 130)",
    focusedColor = "rgb(92, 36, 130);",
    labelColor = "rgb(92, 36, 130);",
    defaultBorderColor = "rgba(51, 51, 51, 0.6)",
    inputStyle = {},
    lableStyle = {},
    inputBoxStyle = {},
    onChange = () => {},
    error = false,
    helperText = "",
    ...otherProps
  } = props;
  return (
    <TextField
      size="small"
      ref={ref}
      sx={{
        input: {
          color: inputTextColor,
          fontFamily: "Poppins",
          fontSize: 16,
          lineHeight: 14,
          ...inputStyle,
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: defaultBorderColor,
          },
          "&:hover fieldset": {
            borderColor: hoverColor,
          },
          "&.Mui-focused fieldset": {
            borderColor: focusedColor,
          },
        },
        "& .MuiFormLabel-root": {
          color: labelColor,
          fontFamily: "OpenSans-Regular",
          ...lableStyle,

          "&.Mui-focused fieldset": {
            color: labelColor,
            fontFamily: "Poppins",
          },
        },
        ".MuiOutlinedInput-root": {
          ...inputBoxStyle,
        },
      }}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      helperText={error && helperText}
      error={error}
      {...otherProps}
    />
  );
});

export default CustomTextField;

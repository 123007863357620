import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
const SlideTransition = (props) => {
  return <Slide {...props} direction="left" />;
};
const Toast = ({
  anchorOrigin = { vertical: "top", horizontal: "right" },
  open = false,
  autoHideDuration = 5000,
  onClose = () => {},
  toastKey,
  children,
}) => {
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      TransitionComponent={SlideTransition}
      ClickAwayListenerProps={{
        touchEvent: false,
        mouseEvent: false,
      }}
      disableWindowBlurListener={true}
      key={toastKey}
    >
      {children}
    </Snackbar>
  );
};

export default Toast;

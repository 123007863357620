import React, { useEffect } from "react";
import { IoCloseCircle } from "react-icons/io5";

const CustomModal = React.forwardRef((props, ref) => {
  const { isVisible = false, onClose = () => {}, children } = props;

  const lockScroll = React.useCallback(() => {
    document.body.style.overflow = "hidden";
  }, []);

  const UnlockScroll = React.useCallback(() => {
    document.body.style.overflow = "unset";
  }, []);

  useEffect(() => {
    lockScroll();
    return () => {
      UnlockScroll();
    };
  }, [UnlockScroll, lockScroll]);

  return (
    <div style={styles.container} ref={ref}>
      <div
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "2px",
          padding: "2px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1px",
          }}
        >
          <span
            style={{
              color: "rgba(92, 37, 131, 1)",
              cursor: "pointer",
            }}
            onClick={(e) => {
              onClose();
            }}
          >
            <IoCloseCircle fontSize="24px" />
          </span>
        </div>
        {children}
      </div>
    </div>
  );
});

const styles = {
  container: {
    position: "absolute",
    display: "flex",
    flex: 1,
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    backgroundColor: "rgba(0,0,0,0.7)",
    width: "100%",
    height: "100vh",
    zIndex: 1000,
    justifyContent: "center",
    alignItems: "center",
    overflow: "unset",
  },
};

export default CustomModal;

import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomTextField from "../common/CustomTextField";
import {
  CalcWidthValue,
  executeScroll,
  isValidPhoneNumber,
  validateEmail,
} from "../helper/CommonValue";
import useWindowDimensions from "../helper/dimensionsHelpers";
import classes from "./SendEmailComponent.module.css";
import { displayToast } from "../common/toast/toastUtils";
import { sendEnquirywebsiteDemo } from "../../api/common";
import { MobileStepper } from "@mui/material";

const SendEmailComponent = (props) => {
  const { onClose = () => {} } = props;
  const { width } = useWindowDimensions();
  const [name, setName] = useState({ value: "", isError: false });
  const [email, setEmail] = useState({
    value: "",
    isError: false,
    helperText: "",
  });
  const [subject, setSubject] = useState({ value: "", isError: false });
  const [message, setMessage] = useState({ value: "", isError: false });
  const [mobile, setMobile] = useState({
    value: "",
    isError: false,
    helperText: "",
  });

  const ValidationForm = useCallback(() => {
    let isErrorAvailable = true;

    if (!name?.value?.length) {
      setName({ value: "", isError: true });
      isErrorAvailable = false;
      // return false;
    }
    if (!email?.value?.length) {
      setEmail({
        value: "",
        isError: true,
        helperText: "Please Enter Email Id.",
      });
      isErrorAvailable = false;
    } else if (!validateEmail(email?.value)) {
      setEmail({
        value: email?.value,
        isError: true,
        helperText: "Please Enter Valid Email Id.",
      });
      isErrorAvailable = false;
    }
    if (!mobile?.value?.length) {
      setMobile({
        value: "",
        isError: true,
        helperText: "Please Enter Mobile No.",
      });
      isErrorAvailable = false;
    } else {
      if (!isValidPhoneNumber(mobile?.value)) {
        setMobile({
          value: mobile?.value,
          isError: true,
          helperText: "Please Enter Valid Mobile No.",
        });
        isErrorAvailable = false;
      }
    }

    if (!subject?.value?.length) {
      setSubject({ value: "", isError: true });
      isErrorAvailable = false;
    }
    if (!message?.value?.length) {
      setMessage({ value: "", isError: true });
      isErrorAvailable = false;
    }
    return isErrorAvailable;
  }, [
    email?.value,
    message?.value?.length,
    mobile?.value,
    name?.value?.length,
    subject?.value?.length,
  ]);

  const onSubmitHandler = useCallback(async () => {
    if (ValidationForm()) {
      const payload = {
        name: name?.value,
        email: email?.value,
        mobile_no: mobile?.value,
        subject: subject?.value,
        message: message?.value,
      };
      const response = await sendEnquirywebsiteDemo(payload);
      if (response.ok) {
        const allData = response?.data;
        if (allData?.status) {
          displayToast({
            type: "success",
            title: "Message send successfully",
          });
          onClose();
        } else {
          displayToast({
            type: "error",
            title: allData?.message,
          });
        }
      }
    }
  }, [
    ValidationForm,
    email?.value,
    message?.value,
    mobile?.value,
    name?.value,
    onClose,
    subject?.value,
  ]);

  // useEffect(() => {
  //   nameRef.current.focus();
  //   executeScroll(nameRef);
  // }, []);

  return (
    <div
      style={{
        padding: "10px 10px 10px 10px",
      }}
    >
      <div
        style={{
          color: "#2c234d",
          fontSize: CalcWidthValue(20, width),
          fontWeight: 600,
        }}
      >
        Get A Call From Our Experts For A Free Demo
      </div>
      <form
        style={{
          gap: "10px",
          display: "flex",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <CustomTextField
          fullWidth
          // ref={nameRef}
          size="small"
          label="Enter Your Name"
          inputStyle={{
            fontSize: CalcWidthValue(12, width),
            lineHeight: width * 0.0068093385,
          }}
          lableStyle={{
            color: "#333333",
            fontSize: width * 0.007333333333,
          }}
          value={name?.value}
          error={name?.isError}
          helperText={"Please Enter Your Name"}
          onChange={(event) => {
            setName({ value: event.target.value, isError: false });
          }}
        />
        <CustomTextField
          fullWidth
          size="small"
          label="Enter your Email Id"
          inputStyle={{
            fontSize: CalcWidthValue(12, width),
            lineHeight: width * 0.0068093385,
          }}
          lableStyle={{
            color: "#333333",
            fontSize: width * 0.007333333333,
          }}
          value={email?.value}
          error={email?.isError}
          helperText={email?.helperText}
          onChange={(event) => {
            setEmail({ value: event.target.value, isError: false });
          }}
        />

        <CustomTextField
          fullWidth
          size="small"
          label="Mobile No."
          inputStyle={{
            fontSize: CalcWidthValue(12, width),
            lineHeight: width * 0.0068093385,
          }}
          lableStyle={{
            color: "#333333",
            fontSize: width * 0.007333333333,
          }}
          value={mobile?.value}
          error={mobile?.isError}
          helperText={mobile?.helperText}
          onChange={(event) => {
            setMobile({ value: event.target.value, isError: false });
          }}
        />

        <CustomTextField
          fullWidth
          size="small"
          label="Subject"
          inputStyle={{
            fontSize: CalcWidthValue(12, width),
            lineHeight: width * 0.0068093385,
          }}
          lableStyle={{
            color: "#333333",
            fontSize: width * 0.007333333333,
          }}
          value={subject?.value}
          error={subject?.isError}
          helperText={"Please Enter Subject"}
          onChange={(event) => {
            setSubject({ value: event.target.value, isError: false });
          }}
        />
        <CustomTextField
          fullWidth
          multiline
          rows={3}
          size="small"
          label="Message"
          inputStyle={{
            fontSize: CalcWidthValue(12, width),
            lineHeight: width * 0.0068093385,
          }}
          lableStyle={{
            color: "#333333",
            fontSize: width * 0.007333333333,
          }}
          value={message?.value}
          error={message?.isError}
          helperText={"Please Enter Message"}
          onChange={(event) => {
            setMessage({ value: event.target.value, isError: false });
          }}
        />
        <div
          className={classes["send_email_contact_button"]}
          onClick={() => {
            onSubmitHandler();
          }}
        >
          Send Message
        </div>
      </form>
    </div>
  );
};

export default SendEmailComponent;

import React, { useContext } from "react";
import classes from "./ContactComponent.module.css";
import ImageComponent from "../common/ImageComponent";
import useWindowDimensions from "../helper/dimensionsHelpers";
import { IoLocationOutline } from "react-icons/io5";
import GlobalContext from "../contaxt/GlobalContext";
import {
  Contact_IMG
} from "../assets";
const ContactComponent = () => {
  const { width } = useWindowDimensions();
  const { openSendEmail, setOpenSendEmail } = useContext(GlobalContext);

  return (
    <section>
      <div className={classes["contact_container"]}>
        {/* left side */}
        <div className={classes["contact_leftSide"]}>
          <ImageComponent
            src={Contact_IMG}
            alt={"logo"}
            className={classes["contact_img"]}
            width="570px"
          />
        </div>
        {/* right side */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "50px 80px 0px 60px",
            height: "auto",
          }}
        >
          <div
            style={{
              height: "402px",
            }}
          >
            <div style={{ marginBottom: "15px" }}>
              <IoLocationOutline color="#6735B9" size={50} />
            </div>
            <div>
              <h4 className={classes["contact-text-header"]}>
                Corporate Office
              </h4>
              <div className={classes["contact_trutab_add"]}>
                Truetab Private Limited HD-009, We Work, K. Raheja Platinum, Sag
                Baug Road, Mumbai, India
              </div>
              <h4 className={classes["contact-text-header"]}>
                Regional Office
              </h4>
              <div className={classes["contact_trutab_add"]}>
                E 52, Info City, Patia Bhubaneswar.
              </div>
            </div>
            <div style={{}}>
              <div
                className={classes["contact_button"]}
                onClick={() => {
                  setOpenSendEmail(true);
                }}
              >
                Contact Us
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "500px", width: "100%" }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1870.533057305653!2d85.80346879591798!3d20.338887219856932!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a19097bb501f957%3A0xe0643aa8db3673ac!2sTruetab!5e0!3m2!1sen!2sin!4v1689059841091!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowFullScreen=""
          loading="lazy"
          title="myFrame"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
};

export default ContactComponent;

import React, { useState } from "react";
import classes from "./PharmacyComponent.module.css";
import ImageComponent from "../common/ImageComponent";

import {
  takeYourPharmacyOnline1_IMG,
  mostComprehensiveMedicineCatalogueNew01_IMG,
  yourVirtualTaskForceNew1_IMG,
  promoteYourStoreOnlineNew01_IMG,
  easyDoorStepDeliveryNew_IMG,
  buyBetterBuySmarterNew01_IMG
} from "../assets";


const PharmacyComponent = () => {
  const pharamcyList = [
    {
      id: 1,
      style: { backgroundColor: "#A286C1", color: "#FFFFFF" },
      headerText: "Take your Pharmacy Online",
      description:
        "Get a power-packed e-commerce website & mobile app to impress and win your customers",
      urlLink:takeYourPharmacyOnline1_IMG,
    },
    {
      id: 2,
      style: { backgroundColor: "#B8D485" },
      headerText: "Most comprehensive medicine catalogue",
      description:
        "Get information about of 1.5 Lakh + medicines with salt details",
      urlLink:mostComprehensiveMedicineCatalogueNew01_IMG,
    },
    {
      id: 3,
      style: { backgroundColor: "#BE8EC2", color: "#FFFFFF" },
      headerText: "Your virtual task force",
      description: "Get your prescriptions digitized by experienced pharmacist",
      urlLink:yourVirtualTaskForceNew1_IMG,
    },
    {
      id: 4,
      style: { backgroundColor: "#ADCEED" },
      headerText: "Promote your Store Online",
      description: "Get the right tools & support to market your store",
      urlLink:promoteYourStoreOnlineNew01_IMG,
    },
    {
      id: 5,
      style: { backgroundColor: "#F8B0AA", color: "#FFFFFF" },
      headerText: "Easy door step delivery",
      description: "Sit & relax. Get orders delivered by truetab fleet",
      urlLink:easyDoorStepDeliveryNew_IMG,
    },
    {
      id: 6,
      style: { backgroundColor: "#FAD6A8" },
      headerText: "Buy better buy smarter",
      description: "Place your orders & get same day delivery with best rates",
      urlLink:buyBetterBuySmarterNew01_IMG,
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  return (
    <div
      style={{
        position: "relative",
        zIndex: 20,
      }}
    >
      <div
        className={classes["pharma-navigate"]}
        style={{
          margin: "auto",
          position: "relative",
          zIndex: 12,
        }}
      >
        <ul className={classes[""]}>
          {pharamcyList.map((value, index) => {
            return (
              <li
                key={value?.id}
                style={{
                  backgroundColor: activeTab === index ? "#A1C53A" : "#FFFFFF",
                }}
                onClick={() => {
                  setActiveTab(index);
                }}
              >
                {index + 1}
              </li>
            );
          })}
        </ul>
      </div>

      <div
        style={{ ...pharamcyList[activeTab].style }}
        className={classes["pharm_tab_desc"]}
      >
        <div
          style={{
            width: "80%",
            display: "flex",
            margin: "auto",
            justifyContent: "center",
          }}
          className={classes["pharm_subtab_desc"]}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              padding: "40px",
            }}
          >
            <ImageComponent
              src={pharamcyList[activeTab].urlLink}
              alt={"logo"}
              width={"100%"}
            />
          </div>
          <div
            style={{
              flex: 1,
              padding: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <h2 style={{ fontSize: "40px" }}>
              {pharamcyList[activeTab].headerText}
            </h2>
            <div
              style={{
                fontSize: "20px",
                paddingTop: "10px",
                lineHeight: "1.8rem",
              }}
            >
              {pharamcyList[activeTab].description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PharmacyComponent;

import React from "react";
import classes from "./TermsConditions.module.css";
import ImageComponent from "../common/ImageComponent";
import { CalcWidthValue } from "../helper/CommonValue";
import useWindowDimensions from "../helper/dimensionsHelpers";
import {
  tns_IMG,
} from "../assets";
const TermsCondition = () => {
  const { width } = useWindowDimensions();

  return (
    <div
      style={{
        padding: `${CalcWidthValue(10, width)}px 0px`,
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div className={classes["terms_header"]}>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <ImageComponent
            src={tns_IMG}
            alt="Image"
            width="100%"
            height="100%"
            
          />
        </div>
        <div className={classes["text_main_header"]}>
          <div className={classes["img_text_header"]}>Term & Conditions</div>
          <div className={classes["img_text_sub_header"]}>
            General Introduction:
          </div>
          <p>
            Truetab Private Limited (hereinafter to be referred as “Company” or
            “We” or “Our” or “Us”), a company duly incorporated under the
            provisions of the Companies Act, 2013 and the rules made thereunder
            including any modification or re-enactment thereof and operating the
            business through website www.truetab.ai, an internet-based portal
            and ‘truetab’ a mobile application, (hereinafter together be
            referred to as “Website”).
          </p>
        </div>
      </div>
      <div
        style={{
          padding: `20px 0px`,
          width: "80%",
          lineHeight: "30px",
        }}
      >
        <div style={{ marginBottom: 50 }}>
          <p>
            Truetab offers you a digital platform through website and mobile
            application subject to acceptance of all the terms, conditions and
            notices contained in these Terms including applicable policies which
            are incorporated herein made by Company at its sole discretion and
            posted on the Website, including by way of imposing an additional
            charge for access to or use of a service(s). Here “You” or “User”
            shall be defined as any natural or legal person who has agreed to
            become a buyer on the Website by providing Account Information
            (defined below) while registering on the Website as a registered
            User using the computer systems. Your use of the Website and the
            Services is subject to the most current version of the Terms made
            available on the Website, at the time of such use. Company shall not
            be liable to deliver any Products purchased by You for delivery, in
            locations outside India. The User agrees to use the service to
            authorise an individual and get the products from the third party on
            his/her behalf.
          </p>
        </div>
        <div style={{ marginBottom: 50 }}>
          <p>
            This document is prepared under the requirement of Information
            Technology Act, 2000 and rules there under including any
            modification or re-enactment thereof for the time being in force.
            This electronic record is generated by a computer system and does
            not require any physical or digital signatures. This terms and
            Conditions are required to be published in the website and mobile
            application in accordance with the provisions of Rule 3 (1) of the
            Information Technology (Intermediaries guidelines) Rules, 2011 that
            require publishing the rules and regulations, privacy policy and
            Terms of Use for access or usage of www.truetab.ai website and its
            mobile applications. The revised terms and conditions shall be
            updated in the portal from time to time.
          </p>
        </div>
        <div style={{ marginBottom: 50 }}>
          <p>
            <b>Changes in Privacy Policy and Terms and Conditions:</b> Truetab
            reserves its right to make any changes or modify these Terms of Use
            or any policy or guidelines of the Website including the Privacy
            Policy, at any time and shall be effective with the time of updating
            of policy and terms and conditions.{" "}
            <b>Digital Platform for Pharmaceutical Products – </b>Truetab
            facilitates the purchase of drugs and other pharmaceutical products,
            nutraceutical products and services (which shall also include Over
            the Counter (OTC) and wellness products) offered by third-party
            retail pharmacies (“Retail Pharmacies”). The Truetab merely provide
            hosting and technology intermediary services to You and persons
            browsing/ visiting the Website. All items offered for sale on the
            Website, and the content made available by the Retail Pharmacies,
            are third party user generated contents and third-party products.
            The Company has no control over such third-party user generated
            contents and/ Pharmaceutical Goods and Services and does not
            originate or initiate the transmission, or select the
            sender/recipient of the transmission, or the information contained
            in such transmission. The authenticity and genuineness of the
            Pharmaceutical Goods and Services made available by the Retail
            Pharmacies through the Website shall be the sole responsibility of
            the Retail Pharmacies. The Company shall have no liability with
            respect to the authenticity of the Pharmaceutical Goods and Services
            being facilitated through the Website and application. The Company
            does not take any representation or warranty as to legal title of
            the Pharmaceutical Goods and Services offered for sale by the Retail
            Pharmacies on the Website and application of truetab at
            www.truetab.ai. The right, title, claim or interest in the products
            sold through the Website and application shall not vest with the
            Company and the Company shall not have any obligations or
            liabilities in respect of any transactions on the Website and
            application. The Company is not responsible for delayed,
            non-performance or breach of contract entered into between You and
            the third-party for purchase and sale of goods or services offered
            by such Retail Pharmacies on the Website. <b>Content –</b> Any
            description which includes information/catalogue of drugs/
            pharmaceutical products or services, on the Website You agree and
            acknowledge that the respective third-party pharmacies are
            exhibiting Third Party Content and Truetab is no concern with this.
            The content available on the Website, including without limitation,
            text, copy, audio, video graphics etc is for general information
            purposes only and does not constitute either an advertisement or
            promotion of any drug being offered for sale by the third-party
            pharmacies on the Website or any professional medical advice,
            diagnosis, treatment or recommendation of any kind. Truetab shall
            not be held responsible for ensuring that the content made available
            are not misleading and describe the actual condition of the
            Pharmaceutical Goods and Services. Also, you acknowledge and
            understand that the Company provides no warranty or representation
            with respect to the authenticity of the information provided on the
            Website and You must do Your own check. The Company shall not be, in
            any event, held responsible or liable for any damages arising out of
            such content by third-parties.{" "}
            <b>Placing an order through Website and Mobile Application –</b>{" "}
            While placing an order for any pharmaceutical products you require a
            valid prescription. You are required to upload a scanned copy of the
            valid prescription on the Website. Our Merchant/Third-Party
            Pharmacies will verify the prescription forwarded by You and in case
            of any discrepancy observed by the third-party pharmacies, the order
            will be cancelled by the third-party pharmacies immediately. You are
            also required to present the original prescription at the time of
            delivery of pharmaceutical products. You shall also allow the
            delivery agent to stamp the original prescription at the time of
            medicine delivery, failing which medicines will not be delivered.
            <b>Substitution of Prescribed Drugs– </b>You acknowledge and accept
            that the order for a substitute of a Prescribed Drugs would only be
            processed if the medical expert/ doctor has himself/ herself
            permitted for any other equivalent generic drug to be dispensed in
            place of the Prescribed Drug in the prescription or if the
            prescription solely lists the salt names instead of a specific brand
            name. You further acknowledge and accept that, in the absence of the
            above, the concerned Third-Party Pharmacy would not dispense a
            substitute drug in place of the Prescription Drug.
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>Invitation to offer for sale- </b>The listing of drugs and other
            pharmaceutical products on the Website by the Third-Party Pharmacies
            is merely an ‘invitation to an offer for sale’ and not an ‘offer for
            sale’. The placement of an order by You shall constitute an offer by
            You to enter into an agreement with the Third-Party Pharmacies
            (“Offer”). Post the Offer from the Third-Party Pharmacies, the
            Company shall send an email to You with the information on the Offer
            along with the details of the concerned Third-Party Pharmacy(s) who
            may undertake the sale, and such an email shall not be considered as
            an acceptance of the Offer. The acceptance of the Offer would only
            be undertaken by the Third-Party Pharmacy(s) after the
            validation/verification of the prescription by such Third-Party
            Pharmacy (in case of Prescription Drugs) and the ascertainment of
            the available stock in the relevant Third-Party Pharmacy(s) (in the
            case of prescription as well as other drugs/ pharmaceutical
            products), by way of a confirmatory email to be sent to You. It is
            hereby clarified that any reference of the term ‘offer/ offered for
            sale by the Retail Pharmacies’, as appearing in these Terms of Use,
            shall be construed solely as an ‘invitation to offer for sale’ by
            any such Retail Pharmacies.
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>Invoicing and Sale- </b>Upon acceptance of the Offer by the
            concerned Retail Pharmacies the Pharmaceutical Drugs and Services
            would be dispensed at the pharmacy, in accordance with the terms of
            the order placed by You. Such dispensation shall also take place
            under the direct/personal supervision of the pharmacist of the
            Retail Pharmacies, wherever required under the applicable law(s).
            The invoice shall be issued by the concerned Retail Pharmacies to
            process and satisfy the order for such Pharmaceutical Drugs and
            Services.
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>Delivery of Pharmaceutical products– </b>The Pharmaceutical
            products and Services shall be delivered by the Retail Pharmacies or
            independent contractors. You accept and acknowledges that the
            courier/ delivery personnel, engaged by the Retail Pharmacies or the
            Company, shall be User Agent of the delivery of the Pharmaceutical
            Drugs and Services from the concerned Retail Pharmacies to the
            address notified by You, with no control over the Pharmaceutical
            Drugs and Services and no additional obligation.
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>Pricing & Payment of pharmaceutical products: </b>Company strives
            to provide You with the best prices possible on the Products You
            require from the Website. The pricing details for purchase of
            Products from the Website are detailed under these Terms. All
            commercial terms such as price, delivery, dispatch of Products
            and/or services are as per principal-to-principal bipartite
            contractual obligations between User and the third parties. Your
            relationship with Company is on a principal-to-principal basis and
            by accepting these Terms of Use You agree that Company is an
            independent contractor for all purposes, and does not have control
            of or liability for the products or services that are listed/
            offered on Company’s Website. Company does not guarantee the
            identity of any third-parties nor does it ensure that a User or a
            third party will complete a transaction. You, as a User, understand
            that upon initiating a requisition on the Website, you are entering
            into a legally binding and enforceable contract with the third party
            to purchase the Products and/or Services from the third party on a
            cash on delivery basis or such other mode as may be specified by
            Company. You, as a User, shall hand over a copy of the prescription,
            at the time of delivery, for the delivery of Products which as per
            law requires a valid prescription signed by a medical practitioner.
            You acknowledge that delivery of such Products shall be made only if
            the third party or its agent/employee is satisfied that the
            prescription is in compliance with applicable norms. You, as a User,
            shall electronically notify Company using the appropriate Company’s
            Website features immediately upon Delivery or non-Delivery within
            the time period as provided in these Terms of Use. Non-notification
            by You of delivery or non-delivery within the time period specified
            in these Terms of Use shall be construed as a deemed delivery in
            respect of that particular order. You, as a User, shall acknowledge
            and accept that the final price of the order might change based on
            the batch of the medicine and availability of stock at the retail
            pharmacy. You, as a User, shall be entitled to claim a refund in
            accordance with the Refund and Return Policy of the retail pharmacy.
            To issue a refund, it is upon the Retail Pharmacies to handle
            customer support and issue a reversal of payment from their end
            (“Cash on Delivery” or “Online Payment”) You acknowledge that
            Company is a mere facilitator and does not handle refunds directly.
            However, we do acknowledge and assist if you wish to report Retail
            Pharmacies for any unfair means. The User and third-party
            acknowledge that Company will not be liable for any damages,
            interests or claims etc. resulting from not processing a
            transaction/transaction price or any delay in processing a
            transaction/transaction price which is beyond control of Company.
            You acknowledge that Company is a mere facilitator and does not fall
            within the per view of the Food Safety and Standards Act, 2006 and
            the rules framed thereunder. You acknowledge that Company is a mere
            facilitator and does not fall within the per view of the Drugs and
            Cosmetics Act, 1940 and the rules framed thereunder. You acknowledge
            that Company is a mere facilitator/intermediary and does not fall
            within the per view of the National Medical Commission Act, 2019,
            and the rules and regulations framed thereunder. The User also
            agrees to pay a nominal fee for the same.
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>Eligibility of Use: </b>Use of the Website is available only to
            persons who can form legally binding contracts under Indian Contract
            Act, 1872. Persons who are “incompetent to contract” within the
            meaning of the Indian Contract Act, 1872 including without
            limitation minors, un-discharged insolvents etc. are not eligible to
            use the Website. You represent that You are of legal age to form a
            binding contract and are not a person barred from receiving services
            under the laws as applicable in India. Notwithstanding the
            foregoing, if You are below the age of eighteen (18) years, you may
            avail the services provided by the Website, through Your legal
            guardian in accordance with the applicable laws. Company reserves
            the right to terminate your membership and / or refuse to provide
            you with access to the Website if it is brought to Company’s notice
            or if it is discovered that you are under the age of 18 years.
            Company reserves the right to refuse access to use the services
            offered at the Website to new Users or to terminate access granted
            to existing Users at any time without according any reasons for
            doing so and You shall have no right to object to the same. You
            shall not have more than one active Account on the Website.
            Additionally, you are prohibited from selling, trading, or otherwise
            transferring Your Account to another party. User Account, Password
            and Security Company shall enable You to make the requisition of
            Products and Services available to You through the Website, only if
            You have provided Company certain required User information,
            including without limitation, name; address; gender; age; phone
            number; valid finance account information; and other details and
            created an account (“Account”) through Company ID and OTP or other
            log-in Id. Terms, uploaded guidelines, rules, additional terms of
            service, or other disclaimer & notices, if any (“Additional Terms”).
            If there is any conflict between the Terms and the Additional Terms,
            the Additional Terms shall take precedence in relation to that
            service. The Website requires You to register as a User by creating
            an Account in order to avail of the services provided by the
            Website. You will be responsible for maintaining the confidentiality
            of the Account Information, and are fully responsible for all
            activities that occur under Your Account. You agree to (a)
            immediately notify Company of any unauthorized use of Your Account
            or Account Information or any other breach of security, and (b)
            ensure that You exit / log out from Your Account at the end of each
            session. Company cannot and will not be liable for any loss or
            damage arising from Your failure to comply with this Section 4. You
            may be held liable for losses incurred by Company or any other User
            of or visitor to the Website due to authorized or unauthorized use
            of Your Account as a result of Your failure in keeping Your Account
            Information secure and confidential or otherwise. The Website also
            allows / shall allow restricted access to the Website for
            unregistered Users. You shall ensure that the Account Information
            provided by You in the Website’s registration form is true,
            complete, accurate and up-to-date. Use of another User’s Account
            Information for availing the services offered by Company is
            expressly prohibited. If You provide any information that is untrue,
            inaccurate, not current or incomplete (or becomes untrue,
            inaccurate, not current or incomplete), or Company has reasonable
            grounds to suspect that such information is untrue, inaccurate, not
            current or incomplete, Company has the right to suspend or terminate
            Your Account and refuse any and all current or future use of the
            Website by You. The Website uses temporary cookies to store certain
            data (that is not sensitive personal data or information) that is
            used by Company for the technical administration of the Website,
            research and development, and for User administration. In the course
            of serving advertisements or optimizing services to You, Company may
            allow authorized third parties to place or recognize a unique cookie
            on your browser. Company does not store personally identifiable
            information in the cookies.
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>
              Further Communications & sending updates for new products or
              services:{" "}
            </b>
            You hereby expressly give your consent and agrees to receive any
            further communications through SMS, phone calls, e-mails from
            Company relating to the Products offered through the Website. A User
            can unsubscribe from receiving further communications from Company
            through SMS and e-mail, phone calls anytime by Visiting
            www.truetab.ai to unsubscribe from messages/ SMS; and Newsletters
            sent at the registered email address and by clicking on the
            unsubscribe option attached at the bottom of newsletter received
            through e-mail.
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>Intellectual Property Rights: </b>The Website and the processes,
            and their selection and arrangement, including but not limited to
            all text, graphics, user interfaces, visual interfaces, sounds and
            music (if any), artwork and computer code (collectively, the
            “Content”) on the Website is owned and controlled by Company and the
            design, structure, selection, coordination, expression, look and
            feel and arrangement of such Content is protected by copyright,
            patent and trademark laws, and various other intellectual property
            rights. The trademarks, logos and service marks displayed on the
            Website (“Marks”) are the property of Company or their third parties
            or respective third parties. You are not permitted to use the Marks
            without the prior consent of Company, the relevant third parties
            that may own the Marks. Unless otherwise indicated or anything
            contained to the contrary or any proprietary material owned by a
            third party and so expressly mentioned, Company owns all
            intellectual property rights to and into the trademark
            www.Easocare.in, and the Website, including, without limitation, any
            and all rights, title and interest in and to copyright, related
            rights, patents, utility models, designs, know-how, trade secrets
            and inventions (patent pending), goodwill, source code, meta tags,
            databases, text, content, graphics, icons, and hyperlinks. Except as
            expressly provided herein, you acknowledge and agree that You shall
            not copy, republish, post, display, translate, transmit, reproduce
            or distribute any Content through any medium without obtaining the
            necessary authorization from Company or third-party owner of such
            Content.
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>Disclaimer Of Warranties & Liabilities: </b>You expressly
            understand and agree that, to the maximum extent permitted by
            applicable law: the website, services and other materials are
            provided by Company on an “as is” basis without warranty of any
            kind, express, implied, statutory or otherwise, including the
            implied warranties of title, non-infringement, merchantability or
            fitness for a particular purpose. without limiting the foregoing,
            Company makes no warranty that (i) the Website or the services will
            meet your requirements or your use of the Website or the services
            will be uninterrupted, timely, secure or error-free; (ii) the
            results that may be obtained from the use of the Website, services
            or materials will be effective, accurate or reliable; (iii) the
            quality of the Website, services or other materials will meet your
            expectations; or that (iv) any errors or defects in the Website,
            services or other materials will be corrected. No advice or
            information, whether oral or written, obtained by you from Company
            or through or from use of the services shall create any warranty not
            expressly stated in the terms to the maximum extent permitted by
            applicable law, Company will have no liability related to user
            content arising under intellectual property rights, label, privacy,
            publicity, obscenity or other laws. Company also disclaims all
            liability with respect to the misuse, loss, modification or
            unavailability of any user content. Company will not be liable for
            any loss that you may incur as a consequence of unauthorized use of
            Your Account or Account information in connection with the Website
            or any services or materials, either with or without your knowledge.
            Company has endeavoured to ensure that all the information on the
            Website is correct, but Company neither warrants nor makes any
            representations regarding the quality, accuracy or completeness of
            any data, information, product or service. Company shall not be
            responsible for the delay or inability to use the website or related
            functionalities, the provision of or failure to provide
            functionalities, or for any information, software, products,
            functionalities and related graphics obtained through the Website,
            or otherwise arising out of the use of the Website, whether based on
            contract, tort, negligence, strict liability or otherwise. further,
            Company shall not be held responsible for non-availability of the
            website during periodic maintenance operations or any unplanned
            suspension of access to the website that may occur due to technical
            reasons or for any reason beyond Company’s control. The user
            understands and agrees that any material or data downloaded or
            otherwise obtained through the Website is done entirely at their own
            discretion and risk and they will be solely responsible for any
            damage to their computer systems or loss of data that results from
            the download of such material or data. Company is not responsible
            for any typographical error leading to an invalid coupon. Company
            accepts no liability for any errors or omissions, with respect to
            any information provided to you whether on behalf of itself or third
            parties. Company shall not be liable for any third-party product or
            services. the advertisement available on e-mail or website with
            respect to the third-party website or the Products are for
            information purpose only. You expressly agree that Your use of the
            Website is at Your risk.
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>NOTE:</b>You agree that Company may, in its sole discretion and
            without prior notice, terminate Your access to the Website and block
            Your future access to the Website if Company determines that You
            have violated these Terms or Additional Terms. You also agree that
            any violation by You of these Terms will constitute an unlawful and
            unfair business practice, and will cause irreparable harm to
            Company, for which monetary damages would be inadequate, and Your
            consent to Company obtaining any injunctive or equitable relief that
            Company deems necessary or appropriate in such circumstances. These
            remedies are in addition to any other remedies Company may have at
            law or in equity. You agree that Company may, in its sole
            discretion, and without prior notice, terminate Your access to the
            Website, for cause, which includes (but is not limited to): (1)
            requests by law enforcement or other government agencies; (2) a
            request by You (self-initiated account deletions); (3)
            discontinuance or material modification of the Website or any
            service offered on or through the Website; or (4) unexpected
            technical issues or problems. If Company does take any legal action
            against You as a result of Your violation of these Terms, Company
            will be entitled to recover from You, and You agree to pay, all
            reasonable attorneys’ fees and costs of such action, in addition to
            any other relief granted to Company.
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>SEVERABILITY </b> If it is found that the any clause of this
            Privacy policy is not as per the law, invalid, illegal or
            unenforceable, that provision or part-provision will, to the extent
            required, be deemed to be deleted, and the validity and
            enforceability of the other provisions of this Privacy Notice will
            not be affected.
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>Termination: </b>The Terms and conditions shall continue to apply
            until it is terminated either by You or Company as set forth below.
            If You want to terminate Your agreement with Company, you may do so
            by (i) not accessing the Website; or (ii) closing Your accounts for
            all of the services that You use, where Company has made this option
            available to You. Company may, at any time, with or without notice,
            terminate the Terms (or portion thereof, such as any individual
            Additional Terms) with you if You breach any of the provisions of
            the Terms, the Privacy Policy or any other terms, conditions, or
            policies that may be applicable to You from time to time (or have
            acted in a manner that clearly shows that You do not intend to, or
            are unable to, comply with the same); Company is required to do so
            by law (for example, where the provision of the services hereunder,
            to You is, or becomes, unlawful);
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>Governing Law: </b>These Terms and all transactions entered into
            on or through the Website and the relationship between You and
            Company shall be governed in accordance with the applicable laws of
            India without reference to conflict of laws principles. You agree
            that all claims, differences and disputes arising under or in
            connection with or in relation here to the Website, the Terms or any
            transactions entered into on or through the Website or the
            relationship between You and Company shall be subject to the
            exclusive jurisdiction of the courts at Mumbai, India and You hereby
            accede to and accept the jurisdiction of such courts.
          </p>
        </div>
        <div style={{ marginBottom: "50px" }}>
          <p>
            <b>Report Abuse:</b> In the event You come across any abuse or
            violation of these Terms or if You become aware of any objectionable
            content on the Website, please report to Company’s customer support
            team.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;

import React from "react";
import classes from "./MultiserviceComponent.module.css";
import MultiserviceMobile from "./MultiserviceMobile";
import { CalcWidthValue } from "../helper/CommonValue";
import useWindowDimensions from "../helper/dimensionsHelpers";

const MultiserviceComponent = () => {
  const { width } = useWindowDimensions();
  return (
    <div
      style={{
        backgroundColor: "#F0DEFC",
        zIndex: 10,
        position: "relative",
        marginTop: "-24px",
        borderRadius: "0px 0px 30px 30px",
        paddingBottom: "50px",
      }}
    >
      <div
        style={{
          paddingTop: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            color: "#5C2482",
            fontSize: CalcWidthValue(45, width), //45px",
            fontWeight: "600",
            paddingTop: "10px",
          }}
        >
          Get the advantage of <br />
          truetab multiplier services
        </div>
      </div>
      <MultiserviceMobile />
    </div>
  );
};

export default MultiserviceComponent;

export const CalcWidthValue = (value, windowWidth) => {
  return (value * windowWidth) / 1440;
};

export const isValidPhoneNumber = (phoneNumber) => {
  let isValid = true;
  let reg1 = /^(6|7|8|9)[0-9]{9}$/; //check if 10 digits and if starts with 6,7,8,9
  let reg2 = /1{9}|2{9}|3{9}|4{9}|5{9}|6{9}|7{9}|8{9}|9{9}|0{9}/; // check if 9 consequtive digits are same
  if (!RegExp(reg1).test(phoneNumber)) isValid = false;
  if (RegExp(reg2).test(phoneNumber)) isValid = false;
  return isValid;
};

export const executeScroll = (
  ref,
  block = "nearest",
  behaviour = "smooth",
  inline = "nearest"
) => {
  if (!ref?.current) return;
  ref.current.scrollIntoView({
    block,
    behaviour,
    inline,
  });
};

export const validateEmail = (email = "") => {
  return Boolean(
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  );
};

import React, { useContext } from "react";
import ImageComponent from "../common/ImageComponent";
import { CalcWidthValue } from "../helper/CommonValue";
import useWindowDimensions from "../helper/dimensionsHelpers";
import classes from "./StoreCard.module.css";
import "animate.css";
import GlobalContext from "../contaxt/GlobalContext";
import {
  mobileAppWebsite1_SVG,
  getMarketingSupport1_SVG,
  doorstepDelivery1_SVG,
  connectWithOurTeam1024x112_IMG,
  bannerImg2New01_IMG
} from "../assets";

const StoreCard = () => {
  const { width } = useWindowDimensions();
  const { setOpenSendEmail } = useContext(GlobalContext);
  const cardList = [
    {
      id: 1,
      urlLink:mobileAppWebsite1_SVG
    },
    {
      id: 2,
      urlLink:getMarketingSupport1_SVG
    },
    {
      id: 3,
      urlLink:doorstepDelivery1_SVG
    },
  ];
  return (
    <div className={classes["store-container"]}>
      <div className={classes["home-right-container"]}>
        <div className={classes["home-right-header-text"]}>
          India’s first tech & service
          <br /> platform for local pharmacies!
        </div>

        <div
          style={{
            display: "flex",
            paddingTop: "10px",
            gap: "20px",
          }}
        >
          <ImageComponent
            className={`animate__animated animate__fadeInUp animate__faster ${classes["store-cardlist"]}`}
            src={cardList[0]?.urlLink}
            alt={"logo"}
            width="100%"
            height={CalcWidthValue(176, width)}
          />
          <ImageComponent
            className={`animate__animated animate__fadeInUp animate__fast ${classes["store-cardlist"]}`}
            src={cardList[1]?.urlLink}
            alt={"logo"}
            width="100%"
            height={CalcWidthValue(176, width)}
          />
          <ImageComponent
            className={`animate__animated animate__fadeInUp animate__delay-0.7s ${classes["store-cardlist"]}`}
            src={cardList[2]?.urlLink}
            alt={"logo"}
            width="100%"
            height={CalcWidthValue(176, width)}
          />
          {/* {cardList.map((value) => {
            return (
              <div key={value.id} className={classes["store-cardlist"]}>
                <ImageComponent
                  src={value?.urlLink}
                  alt={"logo"}
                  width="100%"
                  height="174px"
                />
              </div>
            );
          })} */}
        </div>
        <div
          className={classes["share-img-container"]}
          onClick={() => {
            setOpenSendEmail(true);
          }}
        >
          <ImageComponent
            className={classes["share-img"]}
            src={connectWithOurTeam1024x112_IMG}
            width={CalcWidthValue(300, width)}
            alt={"logo"}
          />
        </div>
      </div>
      <div className={classes["home-left-container"]}>
        <ImageComponent
          // className={classes["home-left-container"]}
          src={bannerImg2New01_IMG}
          alt={"Image"}
          height={CalcWidthValue(600, width)}
          width={CalcWidthValue(666, width)}
        />
      </div>
    </div>
  );
};

export default StoreCard;

import React from "react";
import ImageComponent from "../common/ImageComponent";
import classes from "./EmpowerComponent.module.css";
import useWindowDimensions from "../helper/dimensionsHelpers";
import { CalcWidthValue } from "../helper/CommonValue";
import { textAlign } from "@mui/system";
import {
  millionPharmacy1_IMG,
  Mobile02_IMG,
  secureBackground123_IMG
} from "../assets";
const EmpowerComponent = () => {
  const { width, actualWidth } = useWindowDimensions();
  return (
    <div
      style={{
        zIndex: 8,
        position: "relative",
        marginTop: "-24px",
      }}
    >
      <div
        style={{
          position: "relative",
          
        }}
      >
        <div
          style={{
            backgroundColor: "#FDF9F8",
            position: "relative",
            paddingTop: "100px",
            paddingBottom: "100px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={classes["empower-header-text"]}>
            Empowering local pharmacies
            <br />
            across India
          </div>
        </div>
        <div className={classes["empower-service-customer-image-mobile"]} style={{ justifyContent:'center',display:'flex'}}>
          <div
            className={classes["empower-service-mobile-container"]}
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="20"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-anchor-placement="bottom-bottom"
            style={{
              width:'50%',
              alignSelf:'center'
            }}
          >
            <ImageComponent
              className={classes["empower-service-mobile"]}
              src={Mobile02_IMG}
              alt="img"
              // width={width * 0.8}
              // width={"100%"}
            />
          </div>
        </div>
      </div>
      <div
        className={classes["empower-service-customer"]}

        // style={{
        //   display: "flex",
        //   margin: "auto",
        //   justifyContent: "center",
        //   paddingTop: "200px",
        // }}
      >
        <ImageComponent
          src={secureBackground123_IMG}
          alt="img"
          width={"90%"}
        />
      </div>
      <div
        style={{
          display: "flex",
          margin: "auto",
          justifyContent: "center",
          paddingTop: "80px",
        }}
      >
        <ImageComponent
          src={millionPharmacy1_IMG}
          alt="img"
          width={"100%"}
        />
      </div>
    </div>
  );
};

export default EmpowerComponent;

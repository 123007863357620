import React from "react";
import ImageComponent from "../common/ImageComponent";
import {
  ANI,
  Daily,
  Startup,
  Business,
  ThePrint,
  Zee,
  News,
  BusinessStandard,
} from "../assets";
import NewsCardList from "./NewsCardList";
import { CalcWidthValue } from "../helper/CommonValue";
import useWindowDimensions from "../helper/dimensionsHelpers";

const NewsComponent = () => {
  const { width, actualWidth } = useWindowDimensions();
  //   const { ANI, Daily, Startup, Business, ThePrint, Zee, News }

  const NewsList = [
    {
      id: 1,
      imgUrl: BusinessStandard,
      redirectUrl:
        "https://www.business-standard.com/content/press-releases-ani/truetab-eyes-pan-india-expansion-by-enabling-local-pharmacies-to-overcome-existing-challenges-122092700698_1.html",
      title: "truetab eyes PAN India expansion by enabling local pharmacies...",
      desc: "New Delhi [India], September 27 (ANI/SRV): truetab, India's first tech & service platform...",
      uploadDate: "27 September 2022",
    },
    {
      id: 2,
      imgUrl: ANI,
      redirectUrl:
        "https://www.aninews.in/news/business/business/truetab-eyes-pan-india-expansion-by-enabling-local-pharmacies-to-overcome-existing-challenges20220927133752/",
      title: "truetab eyes PAN India expansion by enabling local pharmacies...",
      desc: "New Delhi [India], September 27 (ANI/SRV): truetab, India's first tech & service platform...",
      uploadDate: "27 September 2022",
    },
    {
      id: 3,
      imgUrl: ThePrint,
      redirectUrl:
        "https://theprint.in/ani-press-releases/truetab-eyes-pan-india-expansion-by-enabling-local-pharmacies-to-overcome-existing-challenges/1144767/",
      title: "truetab eyes PAN India expansion by enabling local pharmacies...",
      desc: "New Delhi [India], September 27 (ANI/SRV): truetab, India's first tech & service platform...",
      uploadDate: "27 September 2022",
    },
    {
      id: 4,
      imgUrl: Daily,
      redirectUrl:
        "https://m.dailyhunt.in/news/india/english/ani67917250816496966-epaper-anieng/truetab+eyes+pan+india+expansion+by+enabling+local+pharmacies+to+overcome+existing+challenges-newsid-n426536520?listname=newspaperLanding&topic=business&index=17&topicIndex=4&mode=pwa&action=click",
      title: "truetab eyes PAN India expansion by enabling local pharmacies...",
      desc: "New Delhi [India], September 27 (ANI/SRV): truetab, India's first tech & service platform...",
      uploadDate: "27 September 2022",
    },
    {
      id: 5,
      imgUrl: Zee,
      redirectUrl:
        "https://www.zee5.com/articles/truetab-eyes-pan-india-expansion-by-enabling-local-pharmacies-to-overcome-existing-challenges",
      title: "truetab eyes PAN India expansion by enabling local pharmacies...",
      desc: "New Delhi [India], September 27 (ANI/SRV): truetab, India's first tech & service platform...",
      uploadDate: "27 September 2022",
    },
    {
      id: 6,
      imgUrl: Startup,
      redirectUrl:
        "https://startupsindia.in/healthtech/truetab-revolutionize-the-unorganized-local-pharmacies-ecosystem-in-india/",
      title: "truetab eyes PAN India expansion by enabling local pharmacies...",
      desc: "New Delhi [India], September 27 (ANI/SRV): truetab, India's first tech & service platform...",
      uploadDate: "27 September 2022",
    },
  ];

  return (
    <div
      style={{
        marginTop: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          paddingTop: "20px",
          backgroundColor: "#5C2482",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "40%",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              paddingLeft: CalcWidthValue(80, width),
              color: "#ffffff",
            }}
          >
            <h2
              style={{
                fontSize: CalcWidthValue(44, width), //"44px",
                fontWeight: 600,
              }}
            >
              In The Press
            </h2>
            <div
              style={{
                fontSize: CalcWidthValue(24, width), //"24px",
                fontWeight: 400,
              }}
            >
              Read what the press has to say <br />
              about us.
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "60%",
            }}
          >
            <ImageComponent
              src={News}
              alt="News"
              height={CalcWidthValue(516, actualWidth)}
              width={CalcWidthValue(686, actualWidth)}
            />
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#ffffff",
            padding: CalcWidthValue(20, width), //"20px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "80%",
              margin: "auto",
              gap: CalcWidthValue(20, width),
              justifyContent: "center",
              display: "flex",
              flexWrap: "wrap",
              paddingBottom: "40px",
            }}
          >
            {NewsList.map((value) => {
              return <NewsCardList key={value?.id} newsData={value} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsComponent;

import errorIcon from "./errorIcon.svg";
import successIcon from "./successIcon.svg";
import warningIcon from "./warningIcon.svg";
import infoIcon from "./infoIcon.svg";

const images = {
  errorIcon,
  successIcon,
  warningIcon,
  infoIcon,
};
export default images;

import React from "react";
import { CalcWidthValue } from "../helper/CommonValue";
import useWindowDimensions from "../helper/dimensionsHelpers";
import classes from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  const { width } = useWindowDimensions();
  return (
    <div
      style={{
        padding: `${CalcWidthValue(80, width)}px 0px`,
        display: "flex",
        overflow: "hidden",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "80%",
        }}
      >
        <div className={classes["img_text_header"]}>
          <b>Privacy Policy</b>
        </div>

        <div className={classes["privacy_text"]}>
          <p className={classes["privacy_text_para"]}>
            Truetab Private Limited (“truetab”) recognises the importance of
            maintaining the privacy of your information. We value your privacy
            and appreciate your trust in us. This privacy policy describes that
            how we collect information from you through our website
            https://www.truetab.ai and mobile applications. It also describes
            the purpose of collecting your information and utilizing that
            information and the measures we take to protect the maintaining the
            privacy of your data. The Privacy policy applies to all visitors of
            website and user of mobile applications.
          </p>
        </div>
        <div className={classes["privacy_text"]}>
          <p className={classes["privacy_text_para"]}>
            You agree and understand that the Company is responsible for
            operation and maintenance of the Platform and all information
            collected and processed on the Platform is collected and processed
            by Us strictly in relation to Our business.
          </p>
        </div>
        <div className={classes["privacy_text"]}>
          <p className={classes["privacy_text_para"]}>
            By clicking on ‘Continue’ during the sign-up process, by using Our
            Platform, or by using the Services, You confirm that You have read,
            understood, and agree with the privacy practices described in this
            Privacy Policy, and the Terms of Use (the “Terms”) and the
            collection, storage and processing of Your information in accordance
            with them.
          </p>
        </div>
        <div className={classes["privacy_text"]}>
          <p className={classes["privacy_text_para"]}>
            This Privacy Policy is incorporated by reference into the Terms. Any
            capitalized terms used but not defined in this Privacy Policy have
            the meaning given to them in the Terms.
          </p>
        </div>
        <div className={classes["privacy_text"]}>
          <p>
            This Privacy Policy is published in compliance with, inter alia:
          </p>
          <ol start={1}>
            <li style={{ marginTop: 10 }}>
              Section 43A of the Information Technology Act, 2000 (“IT Act”);
            </li>
            <li style={{ marginTop: 10 }}>
              Rule 4 of the Information Technology (Reasonable Security
              Practices and Procedures and Sensitive Personal Information)
              Rules, 2011 (“SPDI Rules”); and
            </li>
            <li style={{ marginTop: 10 }}>
              Regulation 3(1) of the Information Technology (Intermediaries
              Guidelines and Digital Media Ethics Code) Rules, 2021
              (“Intermediaries Guidelines”).
            </li>
          </ol>
        </div>
        <div className={classes["privacy_text"]}>
          <p>
            <strong>General Terms:</strong>
          </p>
          <ol start={1}>
            <li style={{ marginTop: 10 }}>
              By accessing or using the Platform or the Service, or by otherwise
              giving Us Your information, You confirm that You have the capacity
              to enter into a legally binding contract under Indian law, in
              particular, the Indian Contract Act, 1872, and have read,
              understood and agreed to the practices and policies outlined in
              this Privacy Policy and agree to be bound by the Privacy Policy.
            </li>
            <li style={{ marginTop: 10 }}>
              You hereby consent to Our collection, use, sharing, and disclosure
              of Your information as described in this Privacy Policy. We
              reserve the right to change, modify, add or delete portions of the
              terms of this Privacy Policy, at Our sole discretion, at any time,
              and any continued use of the App, the Services or the Platform,
              following any such amendments to the Privacy Policy, will be
              deemed as an implicit acceptance of the Privacy Policy in its
              amended form. You are requested to review the Privacy Policy from
              time to time to keep yourself updated with any changes;
              modifications made to the terms hereof.
            </li>
            <li style={{ marginTop: 10 }}>
              If You are accessing or using Services on the App or the Site from
              an overseas location, You do so at Your own risk, and shall be
              solely liable for compliance with any applicable local laws.
            </li>
            <li style={{ marginTop: 10 }}>
              If You do not agree with any of the terms and conditions of this
              Privacy Policy, please do not proceed further to use this Site or
              the App or any Services. This Privacy Policy is subject to change
              at any time without notice. To make sure You are aware of any
              changes, please review this policy on this Site or the App
              periodically.
            </li>
          </ol>
        </div>
        <div className={classes["privacy_text"]}>
          <p>
            <strong>
              What information About You is collected on the Platform?
            </strong>
          </p>
          <p className={classes["privacy_text_para"]}>
            When You access the Site or the App or use the Service(s), You may
            provide, or We may collect information that may specifically
            identify You or any other individual. Given below are the types of
            information that We may collect:
          </p>
        </div>
        <div className={classes["privacy_text"]}>
          <ol start={1}>
            <li style={{ marginTop: 10 }}>
              <b>Information You Give Us:</b> We receive and store any
              information You enter on Our Platform or provide Us in any other
              way. When You register on the Site or the App, We collect
              registration details such as phone number, name, geographical
              address and email address. We verify Your phone number with the
              help of a one-time password sent to Your phone number.
              <p className={classes["privacy_text_para"]}>
                We may also collect information required for providing You
                Services on the Platform including, items You place in the cart,
                pharmaceutical, nutraceutical products including prescription
                drugs or over the counter (OTC) products You may order,
                facilitating booking a consultation with a Medical Practitioner,
                scheduling diagnostic tests, etc. You can choose not to provide
                certain information, but then You may consequently not be able
                to take advantage of many of Our features.
              </p>
              <div
                className={classes["privacy_text"]}
                style={{ marginTop: 50 }}
              >
                <p className={classes["privacy_text_para"]}>
                  We may also collect{" "}
                  <b>Sensitive Personal Data or Information (“SPDI”)</b>
                  about You when You use Our Service(s) on the Platform. This
                  information includes health information We receive from You
                  or, on Your behalf, such as information or records relating to
                  Your medical or health history, health status and laboratory
                  testing results, details of medical practitioner issuing the
                  prescription, details of treatment plans and medication
                  prescribed by a Medical Practitioner, dosage details such as
                  frequency of dosage, alternative medication, medicines ordered
                  by You through the Platform, diagnostic results, other
                  health-related information and any other information inferred
                  therefrom. We may also collect payment information such as
                  Your payment card number, expiration date, billing and
                  shipping address. By using the Service, You consent to the
                  recording, storage, and disclosure of such communications You
                  send or receive for these purposes. We may also store and
                  process prescriptions, treatment notes, recommendations and
                  other data generated by Medical Practitioners on or through
                  the Platform, and data shared by third parties such as
                  diagnostics related information; prescription related
                  information etc and may retain such material for Our records
                  for the duration of You availing the Services or for any such
                  period required or permitted under applicable law.
                </p>
              </div>
            </li>
            <ol start={1}>
              <li style={{ marginTop: 10 }}>
                Primary Contact Details: Your name, Address, email address,
                contact no., country, Government and any other information.
              </li>
              <li style={{ marginTop: 10 }}>
                Personal information: Your Age, sex, date of birth, marital
                status, nationality, occupation, travel history or any other
                personal information as required.
              </li>
              <li style={{ marginTop: 10 }}>
                Financial information: Date of the transactions made, number of
                transactions, transaction history, mode and manner of payment
                and other information required.
              </li>
              <li style={{ marginTop: 10 }}>
                Technical information: website, device and mobile app usage,
                Internet Protocol (IP) address and similar information collected
                via automated means, such as cookies, pixels and similar
                technologies.
              </li>
              <li style={{ marginTop: 10 }}>
                Medical Information: Your current Health related information,
                like your current medical conditions like your medical History,
                details of your current/ previous medical practitioner,
                prescription given by the medical practitioner, Test reports and
                any other information there from
              </li>
              <li style={{ marginTop: 10 }}>
                Product and service information: Your account membership number,
                registration and payment information, and program-specific
                information, when you request products and/or services directly
                from us, or participate in marketing programs.
              </li>
              <li style={{ marginTop: 10 }}>
                Contact List & Images: Name, Mobile Number, Email Id and images
                from your contact list will be uploaded to our database for
                marketing and/or selling your services to those in your Contact
                List, the application will prompt for your explicit consent
                prior to this process
              </li>
              <li style={{ marginTop: 10 }}>
                Images: Images from your device will be uploaded to our database
                for marketing and/or selling your services, the application will
                prompt for your explicit consent prior to you uploading the
                images.
              </li>
            </ol>
            <li style={{ marginTop: 10 }}>
              <b>Information from Other Sources:</b>
              <p>
                We might receive information about You such as order details,
                Your details shared with Our partners etc. from other sources
                including from Our partners, advertisers or third parties
                registered on the Platform and add it to Our account
                information.
              </p>
            </li>
            <li style={{ marginTop: 10 }}>
              <b>Cookies and Other Tracking Technologies:</b>
              <p>
                We utilize “cookies” and other tracking technologies. A “cookie”
                is a small text file that may be used, for example, to collect
                information about activity on the Site or the App. Some cookies
                and other technologies may serve to recall information
                previously indicated or submitted by a User. Most
                browsers/mobile settings allow You to control cookies, including
                whether or not to accept them and how to remove them. You may
                set most browsers/mobile application to notify You if You
                receive a cookie, or You may choose to block cookies with Your
                browser/mobile applications.
              </p>
              <p className={classes["privacy_text_para"]}>
                Tracking technologies may record information such as internet
                domain and host names, internet protocol (IP) addresses, browser
                software and operating system types, stream patterns, and dates
                and times that Our Site or App is accessed. Our use of cookies
                and other tracking technologies allows Us to improve Our Site or
                the App and Your experience.
              </p>
              <p
                className={classes["privacy_text_para"]}
                style={{ marginTop: 50 }}
              >
                At all times, You may refuse all cookies on Your browser or the
                App by changing Your settings to the extent permissible on Your
                device. However, by doing so, You may not be able to use certain
                features on the Platform or take full advantage of all the
                offerings and interest-based advertising. You can remove cookies
                by following directions provided in Your mobile’s “help” file or
                the browser.
              </p>
            </li>
            <li style={{ marginTop: 10 }}>
              <p>
                <b>Third party tools and software:</b>
                We use third party SDKs in Our App. Majority of them are
                different payment options by which You can make a payment for an
                order. In order to enhance Your personal and overall experience
                some of the tools are used therein.
              </p>
            </li>
            <li style={{ marginTop: 10 }}>
              <p>
                <b>Automatic Information:</b>
                We receive and store certain types of information whenever You
                interact with Us. For example, We obtain certain types of
                information when Your web browser accesses the Site or
                advertisements and other content served by or on behalf of
                truetab.ai on other web sites. (OS type and version, App
                version, Device brand, browser and its version details, User
                agent) to see examples of the information We receive. We may
                also receive/store information about Your location and Your
                mobile device, including a unique identifier for Your device. We
                may use this information for internal analysis and to provide
                You with location-based services, such as advertising, search
                results, and other personalized content.
              </p>
            </li>
          </ol>
          <p className={classes["privacy_text_para"]} style={{ marginTop: 50 }}>
            The IT Act and the SPDI Rules regulate the collection, usage,
            retention and disclosure of personal information, which is defined
            under the SPDI Rules as any information that relates to a natural
            person, which, either directly or indirectly, in combination with
            other information available or likely to be available to a body
            corporate, is capable of identifying such person.
          </p>
          <p className={classes["privacy_text_para"]} style={{ marginTop: 50 }}>
            You agree that You are providing all information, including SPDI to
            Us voluntarily. Collection, use and disclosure of personal
            information and SPDI requires Your express consent.
          </p>
          <p className={classes["privacy_text_para"]} style={{ marginTop: 50 }}>
            You are providing Us with Your consent for Our use, collection and
            disclosure of the personal information and SPDI. You may choose to
            not provide Us with personal information or SPDI, but in the event
            that You do so, We will be unable to provide You access to Our App
            or the Site or deliver products or provide Services through Our
            Platform.
          </p>
          <p className={classes["privacy_text_para"]} style={{ marginTop: 50 }}>
            <b>How do We use the information We collect</b>
          </p>
          <div style={{ marginTop: 50 }}>
            <ol start={1}>
              <li style={{ marginTop: 10 }}>
                We use information We collect, in a variety of ways in order to
                provide the Services on the App and the Site and to operate Our
                business, including the following:
              </li>
              <ol start={1}>
                <li style={{ marginTop: 10 }}>
                  To carry out Our obligations arising from Your requests for
                  the products and Services on the Platform;
                </li>
                <li style={{ marginTop: 10 }}>
                  To operate and improve the Platform in order to foster a
                  positive user experience and to improve Our business as a
                  whole;
                </li>
                <li style={{ marginTop: 10 }}>
                  To process and deliver Your order with Us;
                </li>
                <li style={{ marginTop: 10 }}>
                  To enable Your access to Our Site or App to purchase products
                  and provide You Services;
                </li>
                <li style={{ marginTop: 10 }}>
                  To enable You to initiate consultation with Medical
                  Practitioners or facilitating a diagnostic test;{" "}
                </li>
                <li style={{ marginTop: 10 }}>
                  Analysing data, tracking trends, building algorithms, creating
                  databases for rating systems, recommendations engines, etc.;{" "}
                </li>
                <li style={{ marginTop: 10 }}>
                  Research, growth and development of Our or Our group entities’
                  business (including building upon our network of our
                  consulting RMPs/doctors, partners etc);{" "}
                </li>
                <li style={{ marginTop: 10 }}>
                  We use the information that You provide for such purposes such
                  as responding to Your requests, customising Your orders,
                  improving Our Platform or communicating with You{" "}
                </li>
                <li style={{ marginTop: 10 }}>
                  For non-targeting reasons such as frequency capping,
                  compliance, billing, ad reporting or delivery, market research
                  or product development purposes;{" "}
                </li>
                <li style={{ marginTop: 10 }}>
                  To comply with applicable law;
                </li>
                <li style={{ marginTop: 10 }}>
                  To conduct audits and quality assessment procedures;{" "}
                </li>
                <li style={{ marginTop: 10 }}>
                  To analyse the use of Our resources, troubleshooting problems
                  and improving Our Products and Services, by using the
                  information regarding Your mobile device and software.{" "}
                </li>
                <li style={{ marginTop: 10 }}>
                  Contacting users, both during and after an order, for updates,
                  resolution of queries, order details, consultations, follow-up
                  consultations or offering new products or services;
                </li>
                <li style={{ marginTop: 10 }}>
                  To investigate, prevent, or take action regarding illegal
                  activities, suspected fraud, situations involving potential
                  threats to the safety of any person, violations of Our Terms,
                  or as otherwise required by law;
                </li>
                <li style={{ marginTop: 10 }}>
                  To respond to any queries that You may have, and to
                  communicate information to You, including notifications of any
                  promotions or alerts, any changes/updates to the Site, or the
                  introduction of any future fees or charges that We may collect
                  at the time for purchasing products or provision of Our
                  Services to You; or
                </li>
                <li style={{ marginTop: 10 }}>
                  To contact You, by way of SMS, email and phone calls, from
                  time to time to record Your valuable feedback on Our products
                  and Services, as they currently stand, and/or any potential
                  products and services that may be offered in the future.
                </li>
              </ol>

              <li style={{ marginTop: 10 }}>
                We may use “cookies” information and “automatically collected”
                information We collect on the Platform to (i) personalize Our
                services, such as remembering Your information so that You will
                not have to re-enter it during Your visit or the next time You
                avail the Service; (ii) provide customized advertisements,
                content, and information; (iii) monitor and analyze the
                effectiveness of the Service and third-party marketing
                activities; (iv) monitor aggregate site usage metrics such as
                total number of visitors and pages viewed; and (v) track Your
                entries, submissions, and status in any promotions or other
                activities on the Service.
              </li>
              <li style={{ marginTop: 10 }}>
                We may access or store Your information if it is necessary to
                detect, prevent or address fraud and other illegal activity or
                to protect the safety, property or rights of the Platform or
                others.
              </li>
              <li style={{ marginTop: 10 }}>
                We may use information regarding Your location or the location
                of Your device through which You access the Service for a number
                of purposes, including without limitation to confirm You are
                located in a jurisdiction in which the Service is offered and to
                identify an appropriate Medical Practitioner.
              </li>
              <li style={{ marginTop: 10 }}>
                We may collect, analyze, use, publish, create and sell
                de-identified information, of which Your personal or sensitive
                personal information might be a component, for any business or
                other purpose not prohibited by applicable law, including for
                research and marketing purposes. (collectively <b>“Purposes”</b>
                ).
              </li>
            </ol>
            <p>
              <b>Do We share the Information We receive?</b>
            </p>
            <ol start={1}>
              <li style={{ marginTop: 10 }}>
                Information about You and the other Users of the Platform forms
                an integral part of Our business. We only share the personal
                information as described below after ensuring that such third
                party has implemented measures to assure data protection
                measures and that are either subject to this Privacy Policy or
                follow practices at least as protective as those described in
                this Privacy Policy. By using the Site or the App, You accept
                the terms hereof and hereby consent to the storage and
                processing of the personal information and SPDI by third
                parties. We, have arrangements with third parties (including as
                mentioned below) such as affiliates or group companies, service
                providers, retailers, Retail Pharmacies, payment gateways,
                logistics partners who are the intended recipients and may have
                access to the personal information and SPDI but shall not
                disclose such information including SPDI further to any other
                individual / entity. Some of the third parties include:
              </li>
              <ul>
                <li>
                  <b>Retail partners:</b> Some of Your personal information or
                  SPDI will be shared with affiliated retailers, the Retail
                  Pharmacies who supply and service Your order and with the
                  Medical Practitioners. These retailers operate on the Platform
                  or sell medicines and wellness products to You on the Site or
                  the App. You can identify when a third party is involved in
                  Your transactions, and We share customer information related
                  to those transactions with that third party;
                </li>
                <li>
                  <b>Third Party Service Providers:</b> Some of Your personal
                  information or SPDI may be shared with third party service
                  providers, such as diagnostic service providers (including but
                  not limited to labs, collection agents etc), retailers, and
                  logistics providers for the fulfilment of Services and
                  delivery of Your order(s). Examples include fulfilling orders,
                  facilitating and conducting diagnostic tests, including sample
                  collection and processing, delivering packages, sending postal
                  mail, newsletters and e-mail, removing repetitive information
                  from customer lists, analysing data, providing marketing
                  assistance, consultation with Medical Practitioners, providing
                  search results and links (including paid listings and links),
                  processing card payments and providing customer service. They
                  have access to personal information needed to perform their
                  functions including but not limited to performing quality
                  checks on Your testing, for teaching purposes, or for
                  developing normal reference ranges for the diagnostic tests
                  performed. The tools used by such third party service
                  providers to provide the Services, may also collect Your
                  personal information or SPDI during the process of providing
                  such Services. Further, they must process the personal
                  information in accordance with this Privacy Policy and as
                  permitted by applicable law. It is clarified that We will not
                  be responsible and liable for the acts of omissions and
                  commissions of such third parties associated with Us. However,
                  We may facilitate resolving any issue You may face with such
                  third parties;
                </li>
                <li>
                  <b>Our Legal obligations:</b> We may release account and other
                  personal information when We believe in good faith that such
                  release is appropriate to comply with applicable law including
                  to: (i) conform to legal requirements or comply with legal
                  process; (ii) protect rights or property or affiliated
                  companies; (iii) prevent a crime or in interest of national
                  security; or (iv) protect personal safety of Our Users or the
                  public. We may also disclose Your personal information to
                  enforce or apply Our Terms and other agreements; or protect
                  the rights, property or Our safety, safety of Our Users or
                  others. This includes exchanging information with other
                  companies, organisations, government or regulatory authorities
                  for fraud protection and credit risk reduction;
                </li>
                <li>
                  <b>
                    Sharing upon merger or amalgamation or intra-group transfer:
                  </b>{" "}
                  Any third party to which We transfer or sell Our assets, merge
                  or consolidate with, will have the right to continue to use
                  the information (including SPDI) provided to Us by You, in
                  accordance with the Terms and this Privacy Policy. We may
                  disclose information to Our partners, affiliates,
                  subsidiaries, group entities, investors, stakeholders or
                  potential associates in an anonymized and aggregate manner, so
                  that they too may understand how Users use Our Site or App and
                  enable Us to create a better overall experience for You; and
                </li>
                <li>
                  <b>Improving Our business:</b>You acknowledge that We have a
                  right to use a recorded copy of Your telephonic conversation,
                  and e-prescription with Your Medical Practitioner, and Your
                  diagnostic test reports for improving Our Services, marketing
                  and promotional efforts, customize Your experience and aiding
                  You in procuring targeted consultation for any underlying
                  medical condition. These uses improve the Site, the App, and
                  the Services, and better tailor it to meet Your needs, so as
                  to provide You with an efficient, safe and customized
                  experience. We may transfer such personal Information and SPDI
                  to a third party, including persons outside India, to improve
                  product and Service offerings while taking commercially
                  reasonable steps to try and ensure, that the recipient adheres
                  to the applicable laws for ensuring data protection as is
                  adhered by Us.
                </li>
                <li>
                  Transfer to third parties and outside India. Subject to
                  applicable law, We may at Our sole discretion, transfer
                  personal information and SPDI to any other body corporate (as
                  defined under the Information Technology Act, 2000) that
                  ensures at least the same level of data protection as is
                  provided by Us under the terms hereof, located in India or any
                  other country.
                </li>
              </ul>
            </ol>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                By using the Site and the App, You accept the terms hereof and
                hereby consent to Us, sharing with and/or processing of Your
                personal information and SPDI by third parties, including in any
                location outside India, provided that they ensure that Your SPDI
                is protected in compliance with standards that are comparable to
                the standards of protection afforded to it in India or
                equivalent international standards.
              </p>
            </div>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                <b>How Secure Is Information About Me?</b>
              </p>
            </div>
            <ol start={1}>
              <li style={{ marginTop: 10 }}>
                We maintain electronic, physical and procedural safeguards in
                connection with the collection, storage and disclosure of
                personal information (including SPDI). Our security procedures
                may warrant that We may occasionally request proof of identity
                before We disclose personal information to You.
              </li>
              <li style={{ marginTop: 10 }}>
                We work to protect the security of Your information during
                transmission by using Secure Sockets Layer (SSL) software, which
                encrypts information You input in addition to maintaining
                security of Your information as per the international standards
                on “Information Technology Security Techniques Information
                Security Management System-Requirements”.
              </li>
              <li style={{ marginTop: 10 }}>
                We restrict access to personal information, to Our employees and
                agents who need to know that information in order to process it
                for Us, and who are subject to strict contractual
                confidentiality obligations, and may be disciplined or whose
                relationship with Us may terminate if they fail to meet these
                obligations.
              </li>
              <li style={{ marginTop: 10 }}>
                No employee or administrator will have knowledge of Your
                password of Your account on the Site or the App. It is important
                for You to protect Your account against unauthorized access to
                Your password and Your mobile phone, as detailed in the ‘User
                Account, Password and Security’ section of the Terms. You must
                be sure to log off from the Site or the App when You have
                finished use thereof. We do not undertake any liability for any
                unauthorised use of Your account and password.
              </li>
              <li style={{ marginTop: 10 }}>
                If You suspect any unauthorized use of Your account, You must
                immediately notify Us by sending an email to the contact details
                indicated in the contact section. You shall be liable to
                indemnify Us due to any loss suffered by Us due to such
                unauthorized use of Your account or password.
              </li>
              <li style={{ marginTop: 10 }}>
                Under certain circumstances, We shall not be able to take Your
                prior consent before disclosing Your information in case the
                information is demanded by government agencies or otherwise
                mandated under law to obtain SPDI, or during investigation of
                cyber incidents, prosecution of offenses etc.
              </li>
              <li style={{ marginTop: 10 }}>
                Further, We shall not be responsible for any breach of security
                or for any actions of any third parties or events that are
                beyond Our reasonable control including but not limited to acts
                of government, computer hacking, unauthorised access to computer
                data and storage device, computer crashes, breach of security
                and encryption, poor quality of internet service or telephone
                service of the User, etc.
              </li>
            </ol>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                <b>What Information Can I Access?</b>
              </p>
              <p className={classes["privacy_text_para"]}>
                The Platform gives You access to a broad range of information
                about Your account and Your interactions with the Platform for
                the limited purpose of viewing and, in certain cases, modifying,
                deleting information provided on the App or the Site. You may
                rectify any inaccurate or deficient personal information or
                SPDI, as feasible. You have the option to opt-out of optional
                services such as receiving promotional materials etc. and should
                You desire to opt-in to such services at the time of signing up
                You can choose do so.
              </p>
            </div>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                <b>Third-party links </b>
              </p>
              <p className={classes["privacy_text_para"]}>
                The Site or the App may include hyperlinks to various external
                websites, and may also include advertisements, and hyperlinks to
                applications, content or resources (<b>“Third Party Links”</b>).
                We have no control over such Third Party Links present on the
                Site or the App, which are provided by persons or companies
                other than Us. You acknowledge and agree that We are not
                responsible for any collection or disclosure of Your information
                by any external sites, applications, companies or persons
                thereof. The presence of any Third Party Links on Our Site or
                App, cannot be construed as a recommendation, endorsement or
                solicitation for the same, or any other material on or available
                via such Third Party Links.
              </p>
            </div>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                You further acknowledge and agree that We are not liable for any
                loss or damage which may be incurred by You as a result of the
                collection and/or disclosure of Your information via Third Party
                Links, as a result of any reliance placed by You on the
                completeness, accuracy or existence of any advertising, products
                services, or other materials on, or available via such Third
                Party Links. This will include all transactions, and information
                transmitted therein, between You and any such third party sites
                or applications or resources, such transactions are strictly
                bi-partite. We shall not be liable for any disputes arising from
                or in connection with such transactions between You and the
                aforementioned third parties.
              </p>
            </div>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                Such third party websites, and external applications or
                resources, accessible via the Third Party Links may have their
                own privacy policies governing the collection, storage,
                retention and disclosure of Your information that You may be
                subject to. We recommend that You exercise reasonable diligence,
                as You would in traditional offline channels and practice
                judgment and common sense before committing to any transaction
                or exchange of information, including but not limited to
                reviewing the third party website or application’s privacy
                policy.
              </p>
            </div>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                <b>Retention of Information</b>
              </p>
            </div>
            <ol start={1}>
              <li>
                Any personal information or SPDI collected in accordance with
                this Privacy Policy will be collected by Us and retained or
                stored either by Us or by Strad Hosting and Development LLP,
                Wadala Udyog Bhavan, Wadala (W), Mumbai – 400031 Maharashtra,
                India
              </li>
              <li>
                We also have measures in place such that Your SPDI which is in
                Our possession or under Our control, is destroyed and/or
                anonymized as soon as it is reasonable to assume that: (i) the
                purposes for which Your SPDI has been collected have been
                fulfilled; and (iii) retention is no longer necessary for any
                other reason, or under applicable law.
              </li>
              <li>
                We may, however, reserve the right to retain and store Your
                personal information for Our business purposes, whether such
                personal information has been deleted or not. After a period of
                time, Your data may be anonymized and aggregated and then may be
                held by Us as long as necessary, to enable purchases of products
                and provision of services or for analytics purposes.{" "}
              </li>
              <li>
                If You wish to withdraw Your consent for processing Your
                personal information and SPDI, cancel Your account, or request
                that We no longer use Your personal information and SPDI to
                deliver Our products or provide You services, please contact Us
                at details indicated in the contact section. Please note however
                that Your withdrawal of consent or cancellation of account may
                result in Us not being able to deliver You products or provide
                You with Our services, or terminate any existing relationship
                that We may have with You.
              </li>
              <li>
                Please note that uninstalling the App will not result in
                deletion of Your personal information or SPDI.
              </li>
            </ol>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                <b>Changes to Your Information</b>
              </p>
            </div>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                You may review, correct, update, change the information that You
                have provided by logging into Your account. However, You are not
                permitted to delete any part of the personal information or any
                other information generated on the Platform. You may update Your
                information at any point by writing to Us at the details
                indicated below in the contact section.
              </p>
            </div>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                Should You choose to update Your personal information or SPDI or
                modify it in a way that is not verifiable by Us, or leads to
                such information being incorrect, We will be unable to provide
                You with access to Our Site, App or the Services, as described
                under the Terms, and such modification may be regarded as the
                User seeking to discontinue his or her access to Our Site, App
                or the Services.
              </p>
            </div>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                We reserve the right to verify and authenticate Your identity
                and Your personal information in order to ensure accurate
                delivery of products and services. Access to or correction,
                updating or deletion of Your personal information or SPDI may be
                denied or limited by Us if it would violate another person’s
                rights and/or is not otherwise permitted by applicable law.
              </p>
            </div>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                <b>Notices</b>
                <br />
                If You have any concern about privacy or grievances on the Site
                or the App, please contact Us with a thorough description and We
                will try to resolve the issue for You. If You have any concerns
                or questions in relation to this Privacy Policy, You may address
                them to Our grievance officer at:
              </p>
              <p className={classes["privacy_text_para"]}>
                <b>Name: </b>Mr. Amarpreet Singh Modi
              </p>
              <p className={classes["privacy_text_para"]}>
                <b>Address: </b>Truetab Private Limited HD-009, We Work, K.
                Raheja Platinum, Sag Baug Road, Mumbai, India
              </p>
              <p className={classes["privacy_text_para"]}>
                <b>E-mail: </b>amarpreet@truetab.ai
              </p>
            </div>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                We shall endeavour to resolve Your grievances within one month
                from the date of receipt of such grievance.
              </p>
            </div>
            <div className={classes["privacy_text"]}>
              <p className={classes["privacy_text_para"]}>
                <b>Miscellaneous</b>
              </p>
            </div>
            <ol start={1}>
              <li>
                <u>Disclaimer </u>: We cannot ensure that all of Your personal
                information and SPDI will never be disclosed in ways not
                otherwise described in this Privacy Policy. Therefore, although
                We are committed to protecting Your privacy, We do not promise,
                and You should not expect, that Your information or private
                <br />
                communications will always remain private. As a User of the Site
                or the App, You understand and agree that You assume all
                responsibility and risk for Your use of the Site or the App, the
                internet generally, and the information You post or access and
                for Your conduct on and off the Site or the App.
              </li>
              <li>
                <u>Indemnity </u>: You agree and undertake to indemnify Us in
                any suit or dispute by any third party arising out of disclosure
                of information by You to third parties either through Our Site
                or App or otherwise and Your use and access of websites,
                applications and resources of third parties. We assume no
                liability for any actions of third parties with regard to Your
                personal information or SPDI which You may have disclosed to
                such third parties.
              </li>
              <li>
                <u> Severability </u>: Each clause of this Privacy Policy shall
                be and remain separate from and independent of and severable
                from all and any other clauses herein except where otherwise
                expressly indicated or indicated by the context of the Privacy
                Policy. The decision or declaration that one or more clauses are
                null and void shall have no effect on remaining clauses of this
                Privacy Policy.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

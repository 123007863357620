import React, { useEffect } from "react";
import ImageComponent from "../common/ImageComponent";
import { CalcWidthValue } from "../helper/CommonValue";
import classes from "./MultiserviceComponent.module.css";
import useWindowDimensions from "../helper/dimensionsHelpers";
import AOS from "aos";
import "aos/dist/aos.css";

import {
  MobileCopy_IMG,
  Mobile01Copy_IMG,
  line2Dot2_IMG,
  AmbulanceBooking1_IMG,
  dot2_IMG,
  Insurance_IMG,
  line3dot2_IMG,
  AndManyMore_IMG,
  LabTest_IMG,
  DoctorConsultation_IMG
} from "../assets";

const MultiserviceMobile = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const { width } = useWindowDimensions();
  return (
    <div className={classes["multi-service-mobile-container"]}>
      <div className={classes["multi-service-mobile-subcontainer"]}>
        <div
          className={classes["multi-first-mobile-container"]}
          data-aos="fade-up"
          data-aos-offset="100"
          data-aos-delay="10"
          data-aos-duration="00"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          // data-aos-once="false"
          data-aos-anchor-placement="top-center"
        >
          <ImageComponent
            className={`${classes["multi-first-mobile"]}`}
            src={MobileCopy_IMG}
            alt="Image"
            height={CalcWidthValue(574, width)}
            // height={"574px"}
          />
        </div>
        <div className={classes["line-container"]}>
          <div
            className={classes["multi-second-mobile-container"]}
            data-aos="fade-up"
            data-aos-offset="100"
            data-aos-delay="20"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            // data-aos-once="false"
            data-aos-anchor-placement="top-center"
          >
            <ImageComponent
              className={`${classes["multi-second-mobile"]}`}
              src={Mobile01Copy_IMG}
              alt="Image"
              //   height={"574px"}
              height={CalcWidthValue(574, width)}
            />
          </div>

          <div className={`${classes["multi-ambulace-line-container"]}`}>
            <ImageComponent
              src={line2Dot2_IMG}
              alt="Image"
              height={CalcWidthValue(200, width)}
              //   height={"200px"}
            />
          </div>
          <div className={classes["multi-ambulace-container"]}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ImageComponent
                src={AmbulanceBooking1_IMG}
                alt="Image"
                height={"62px"}
                width={"62px"}
              />
            </div>
            <div className={classes["multi-service-text"]}>
              Ambulance Booking
            </div>
          </div>
          <div className={classes["multi-Insurance-line"]}>
            <ImageComponent
              src={dot2_IMG}
              alt="Image"
              //   height={"200px"}
              height={CalcWidthValue(200, width)}
            />
          </div>
          <div className={classes["multi-Insurance-container"]}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ImageComponent
                src={Insurance_IMG}
                alt="Image"
                height={"62px"}
                width={"62px"}
              />
            </div>
            <div className={classes["multi-service-text"]}>Insurance</div>
          </div>

          <div className={classes["multi-doctor-line"]}>
            <ImageComponent
              src={line3dot2_IMG}
              alt="Image"
              height={CalcWidthValue(250, width)}
              //   height={"250px"}
            />
          </div>
          <div className={classes["multi-doctor-container"]}>
            <div className={classes["multi-service-text"]}>
              Doctor Consultation
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ImageComponent
                src={DoctorConsultation_IMG}
                alt="Image"
                height={"62px"}
                width={"62px"}
              />
            </div>
          </div>

          <div className={classes["multi-more-line"]}>
            <ImageComponent
              src={line2Dot2_IMG}
              alt="Image"
              height={CalcWidthValue(230, width)}
              //   height={"250px"}
            />
          </div>
          <div className={classes["multi-more-container"]}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ImageComponent
                src={AndManyMore_IMG}
                alt="Image"
                height={"62px"}
                width={"62px"}
              />
            </div>
            <div className={classes["multi-service-text"]}>And Many more</div>
          </div>

          <div className={classes["multi-lab-line"]}>
            <ImageComponent
              src={dot2_IMG}
              alt="Image"
              height={CalcWidthValue(250, width)}
              //   height={"250px"}
            />
          </div>
          <div className={classes["multi-lab-container"]}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ImageComponent
                src={LabTest_IMG}
                alt="Image"
                height={"62px"}
                width={"62px"}
              />
            </div>
            <div className={classes["multi-service-text"]}>Lab Test</div>
          </div>
        </div>

        <div className={classes["multi-service-list"]}>
          <div
            style={{
              paddingLeft: "10px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ImageComponent
              //   className={`${classes["multi-first-mobile"]}`}
              src={LabTest_IMG}
              alt="Image"
              height={"66px"}
            />
            <span className={classes["multi-service-text"]}>Lab Test</span>
          </div>
        </div>
        <div className={classes["multi-service-list"]}>
          <div
            style={{
              paddingLeft: "10px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ImageComponent
              //   className={`${classes["multi-first-mobile"]}`}
              src={Insurance_IMG}
              alt="Image"
              height={"66px"}
            />
            <span className={classes["multi-service-text"]}>Insurance</span>
          </div>
        </div>
        <div className={classes["multi-service-list"]}>
          <div
            style={{
              paddingLeft: "10px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ImageComponent
              //   className={`${classes["multi-first-mobile"]}`}
              src={DoctorConsultation_IMG}
              alt="Image"
              height={"66px"}
            />
            <span className={classes["multi-service-text"]}>
              Doctor-Consultation
            </span>
          </div>
        </div>
        <div className={classes["multi-service-list"]}>
          <div
            style={{
              paddingLeft: "10px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ImageComponent
              //   className={`${classes["multi-first-mobile"]}`}
              src={AmbulanceBooking1_IMG}
              alt="Image"
              height={"66px"}
            />
            <span className={classes["multi-service-text"]}>
              Ambulance Booking
            </span>
          </div>
        </div>
        <div className={classes["multi-service-list"]}>
          <div
            style={{
              paddingLeft: "10px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ImageComponent
              //   className={`${classes["multi-first-mobile"]}`}
              src={AndManyMore_IMG}
              alt="Image"
              height={"66px"}
            />
            <span className={classes["multi-service-text"]}>And Many more</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiserviceMobile;

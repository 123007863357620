import React from "react";

const ImageComponent = (props) => {
  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://www.smaroadsafety.com/wp-content/uploads/2022/06/no-pic.png";
  };

  return (
    <>
      <img
        {...props}
        src={props?.src}
        loading="lazy"
        alt="Logo"
        onError={addDefaultSrc}
      />
    </>
  );
};

export default ImageComponent;

import React, { useEffect, useState } from "react";
import classes from "./Toast.module.css";
import Toast from "./Toast";
import images from "../../assets/svg";

const WarningToast = ({ title, message, autoHideDuration, toastKey }) => {
  const { warningIcon } = images;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, [toastKey]);

  return (
    <Toast
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={open}
      onClose={() => setOpen(false)}
      autoHideDuration={autoHideDuration}
      toastKey={toastKey}
    >
      <div className={`${classes["toast"]} ${classes["warning-toast"]}`}>
        <div className={classes["toast-title-container"]}>
          <img
            className={classes["toast-icon"]}
            src={warningIcon}
            alt="warning"
          />
          <div className={classes["toast-title"]}>{title}</div>
        </div>
        <div className={classes["toast-message"]}>{message}</div>
      </div>
    </Toast>
  );
};

export default WarningToast;

import React from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import classes from "./BuisnessComponent.module.css";
import {
  TrueTab01_MP4
} from "../assets";
const BuisnessComponent = () => {
  return (
    <div
      style={{
        marginTop: "-24px",
        backgroundColor: "#F3EEF7",
        borderRadius: "0px 0px 30px 30px",
        zIndex: 12,
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
        className={classes["buisness_container"]}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flex: 1,
          }}
        >
          <video
            width="100%"
            height="100%"
            autoPlay
            muted
            loop
            style={{
              borderRadius: "0px 0px 30px 30px",
            }}
          >
            <source
              src={TrueTab01_MP4}
              type="video/mp4"
            />
          </video>
        </div>
        {/* right side */}
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            gap: "80px",
            paddingBottom: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                paddingLeft: "10px",
              }}
            >
              <div className={classes["buisness_arrow"]}>
                <FaArrowAltCircleRight size={38} />
              </div>
            </div>
            <div style={{ paddingLeft: "10px" }}>
              <div className={classes["buisness_text"]}>
                Advanced Business <br />
                Dash board
              </div>
              <div className={classes["buisness_desc_text"]}>
                Business Information at your <br />
                finger tips
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                paddingLeft: "10px",
              }}
            >
              <div className={classes["buisness_arrow"]}>
                <FaArrowAltCircleRight size={38} />
              </div>
            </div>
            <div style={{ paddingLeft: "10px" }}>
              <div className={classes["buisness_text"]}>
                Track Customer Deliveries
              </div>
              <div className={classes["buisness_desc_text"]}>
                Delivery made easy and simple
                <br />
                with our delivery integrations.
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                paddingLeft: "10px",
              }}
            >
              <div className={classes["buisness_arrow"]}>
                <FaArrowAltCircleRight size={38} />
              </div>
            </div>
            <div style={{ paddingLeft: "10px" }}>
              <div className={classes["buisness_text"]}>
                Accept Digital Payments
              </div>
              <div className={classes["buisness_desc_text"]}>
                Receive digital payments
                <br />
                in your account directly
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuisnessComponent;

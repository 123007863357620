import React from "react";
import classes from "./TrueTabNewsComponent.module.css";
import ImageComponent from "../common/ImageComponent";
import { ANI_LOGO, Business, Daily, Startup, ThePrint, Zee } from "../assets";
import { CalcWidthValue } from "../helper/CommonValue";
import useWindowDimensions from "../helper/dimensionsHelpers";

const TrueTabNewsComponent = () => {
  const { width } = useWindowDimensions();
  return (
    <div
      style={{
        zIndex: 9,
        position: "relative",
        marginTop: "-24px",
        backgroundColor: "#F3EEF7",
        borderRadius: "0px 0px 40px 40px",
      }}
    >
      <div
        style={{
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
      >
        <div className={classes["news-hedader-text"]}>truetab in News</div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            padding: "10px",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <ImageComponent
            src={ANI_LOGO}
            alt="ANI"
            width={CalcWidthValue(195, width)}
            height={CalcWidthValue(100, width)}
          />
          <ImageComponent
            src={Daily}
            alt="ANI"
            width={CalcWidthValue(195, width)}
            height={CalcWidthValue(100, width)}
          />
          <ImageComponent
            src={Startup}
            alt="ANI"
            width={CalcWidthValue(195, width)}
            height={CalcWidthValue(100, width)}
          />
          <ImageComponent
            src={Business}
            alt="ANI"
            width={CalcWidthValue(195, width)}
            height={CalcWidthValue(100, width)}
          />
          <ImageComponent
            src={ThePrint}
            alt="ANI"
            width={CalcWidthValue(195, width)}
            height={CalcWidthValue(100, width)}
          />
          <ImageComponent
            src={Zee}
            alt="ANI"
            width={CalcWidthValue(195, width)}
            height={CalcWidthValue(100, width)}
          />
        </div>
      </div>
    </div>
  );
};

export default TrueTabNewsComponent;

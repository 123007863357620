import React, { useContext, useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import HeaderComponent from "./Header/HeaderComponent";
import FooterComponent from "./footer/FooterComponent";
import GlobalContext from "./contaxt/GlobalContext";
import CustomModal from "./common/CustomModal";
import { CalcWidthValue, executeScroll } from "./helper/CommonValue";
import useWindowDimensions from "./helper/dimensionsHelpers";
import SendEmailComponent from "./EmailSend/SendEmailComponent";

const MainContainer = () => {
  const { openSendEmail, setOpenSendEmail } = useContext(GlobalContext);
  const { width } = useWindowDimensions();
  const modelRef = useRef();

  useEffect(() => {
    if (openSendEmail) {
      modelRef.current.focus();
      executeScroll(modelRef);
    }
  }, [openSendEmail]);

  return (
    <>
      <HeaderComponent />
      <div
        style={{
          paddingTop: "60px",
        }}
      >
        <Outlet />
      </div>
      <FooterComponent />

      {openSendEmail && (
        <CustomModal
          ref={modelRef}
          onClose={() => {
            setOpenSendEmail(false);
          }}
        >
          <div
            style={{
              width: CalcWidthValue(456, width),
            }}
          >
            <SendEmailComponent
              onClose={() => {
                setOpenSendEmail(false);
              }}
            />
          </div>
        </CustomModal>
      )}
    </>
  );
};

export default MainContainer;
